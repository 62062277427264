
import { defineComponent, ref, toRefs, watch, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useRoute, useRouter } from 'vue-router';
import axios from "axios";
import { showModal } from "@/core/helpers/dom";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
  name: "VehicleDetail",
  props: {
  },
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  emits: [],
  setup(props, { emit }) {
    const route = useRoute();
    const dataVehicle = ref<any>({});
    const titulo = ref<any>();
    const portada = ref<string>("");
    const nombreAuto = ref<string>("");
    const precio = ref<any>();
    const vin = ref<string>("");
    const anio = ref<string>("");
    const brand = ref<string>("");
    const model = ref<string>("");
    const version = ref<string>("");
    const tipo = ref<string>("");
    const transmision = ref<string>("");
    const color = ref<string>("");
    const kilometraje = ref<string>("");
    const descAdicional = ref<string>("");
    const dueno = ref<string>("");
    const servicios = ref<string>("");
    const certificado = ref<string>("");
    const desperfectoImages = ref<any>([]);
    const desperfectoDescription = ref<string>("");
    const vehicleImages = ref<any>([]);
    const status = ref<string>("");
    const dropShow = ref<boolean>(false);
    const idAuto = ref<any>();
    const venderVehicleRef = ref<any>();
    const disponibleVehicleRef = ref<any>();
    const sellVehicleRef = ref<any>();
    const piso = ref<any>();
    const citas_agendadas = ref<any>();
    const veces_apartado = ref<any>();
    const views = ref<any>();
    const indexImageDesperfectos = ref<number>(0);
    const indexImageFotos = ref<number>(0);
    const userRole = ref<any>();
    const tipoRol = ref<string>("");
    const specs = ref<any>([]);

    onMounted(async () => {
     if(route.params.id){
        let userRolObj:any = localStorage.getItem('userRole');
        userRole.value = JSON.parse(userRolObj);
        tipoRol.value = userRole.value.tipo.name;
        idAuto.value = route.params.id;
        await getSpecs(route.params.id);
        
        try {
            let url = process.env.VUE_APP_API_URL+'/api/vehicles/'+route.params.id;
            const { data } = await axios.get(url);
            dataVehicle.value = data.data;
            nombreAuto.value = dataVehicle.value.brand.brand + ' ' + dataVehicle.value.model.model;
            precio.value = formatPrice(dataVehicle.value.price);
            titulo.value = "  Agencias - " + dataVehicle.value.agency.name + ' - Vehículos -';
            vin.value = dataVehicle.value.vin.toUpperCase();
            anio.value = dataVehicle.value.year.year;
            brand.value = dataVehicle.value.brand.brand;
            model.value = dataVehicle.value.model.model;
            version.value = dataVehicle.value.version.version;
            tipo.value = dataVehicle.value.type.type;
            transmision.value = dataVehicle.value.transmission.transmission;
            color.value = dataVehicle.value.color.color;
            kilometraje.value = formatPrice(dataVehicle.value.mileage);
            console.log(dataVehicle);
            dataVehicle.value.images.forEach((value, ind) => {
                if(portada.value == "" && value.type == "Vehiculo"){
                    portada.value = process.env.NODE_ENV === 'production' ? value.path : value.path.replace('http://localhost', process.env.VUE_APP_API_URL);
                }
                if(value.type == 'Desperfecto'){
                    if(desperfectoDescription.value == ''){
                        desperfectoDescription.value = value.desc;
                    }
                    let path = process.env.NODE_ENV === 'production' ? value.path : value.path.replace('http://localhost', process.env.VUE_APP_API_URL);
                    desperfectoImages.value.push(path);
                }
                if(value.type == 'Vehiculo'){
                    let path = process.env.NODE_ENV === 'production' ? value.path : value.path.replace('http://localhost', process.env.VUE_APP_API_URL);
                    vehicleImages.value.push(path);
                }
            });
            descAdicional.value = dataVehicle.value.description;
            dueno.value = dataVehicle.value.unique_owner;
            servicios.value = dataVehicle.value.agency_service;
            certificado.value = dataVehicle.value.has_certificate;
            status.value = dataVehicle.value.status.status;
            piso.value = dataVehicle.value.days_in_floor;
            citas_agendadas.value = dataVehicle.value.citas_agendadas;
            veces_apartado. value = dataVehicle.value.veces_apartados;
            views.value = dataVehicle.value.views;
        } catch (error) {
            console.log(error);
        }
     }
    });

    const formatPrice = (value) => {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const mostrarDrop = () => {
        dropShow.value = !dropShow.value;
    }

    const apartarVehiculo = async () => {
        let url = process.env.VUE_APP_API_URL+'/api/vehicles/change-status/'+idAuto.value+'/3';
        try {
            const { data } = await axios.post(url);
            if(data.success){
                status.value = "Apartado";
                dropShow.value = !dropShow.value;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const disponibleVehiculo = async () => {
        let url = process.env.VUE_APP_API_URL+'/api/vehicles/change-status/'+idAuto.value+'/2';
        try {
            const { data } = await axios.post(url);
            if(data.success){
                status.value = "Disponible";
                dropShow.value = !dropShow.value;
                hideModal(disponibleVehicleRef.value);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getSpecs = async (id) => {
        try {
            const { data } = await axios.get(process.env.VUE_APP_API_URL + "/api/site/specs/" + id);
            // console.log(data);
            
            if(data.data != undefined){
                for(let key in data.data){
                    if(data.data[key].value == true || data.data[key].value == false){
                        specs.value.push(data.data[key]);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const showChangeStatus = async () => {
        showModal(disponibleVehicleRef.value);
    };

    const venderModal = async () => {
        showModal(venderVehicleRef.value);
    };

    const aceptarVendido = async () => {
        hideModal(sellVehicleRef.value);
    };

    const venderVehiculo = async () => {
        let url = process.env.VUE_APP_API_URL+'/api/vehicles/change-status/'+idAuto.value+'/4';
        try {
            const { data } = await axios.post(url);
            if(data.success){
                status.value = "Vendido";
                dropShow.value = !dropShow.value;
                hideModal(venderVehicleRef.value);
                showModal(sellVehicleRef.value)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const setImageDesperfectos = (index) => {
        indexImageDesperfectos.value = index;
    };

    const nextImageDesperfectos = () => {
        indexImageDesperfectos.value ++;
    };

    const prevImageDesperfectos = () => {
        if(indexImageDesperfectos.value > 0){
            indexImageDesperfectos.value --;
        }
    };

    const setImageFotos = (index) => {
        indexImageFotos.value = index;
    };

    const prevImageFotos = () => {
        if(indexImageFotos.value > 0){
            indexImageFotos.value --;
        }
    };

    const nextImageFotos = () => {
        indexImageFotos.value ++;
    };

    return {
        nextImageFotos,
        prevImageFotos,
        setImageFotos,
        indexImageFotos,
        prevImageDesperfectos,
        nextImageDesperfectos,
        indexImageDesperfectos,
        setImageDesperfectos,
        dataVehicle,
        formatPrice,
        titulo,
        portada,
        nombreAuto,
        precio,
        vin,
        anio,
        brand,
        model,
        version,
        tipo,
        transmision,
        color,
        kilometraje,
        descAdicional,
        dueno,
        servicios,
        certificado,
        desperfectoImages,
        desperfectoDescription,
        vehicleImages,
        status,
        dropShow,
        mostrarDrop,
        idAuto,
        apartarVehiculo,
        disponibleVehiculo,
        venderVehicleRef,
        disponibleVehicleRef,
        venderModal,
        venderVehiculo,
        sellVehicleRef,
        aceptarVendido,
        piso,
        showChangeStatus,
        userRole,
        tipoRol,
        citas_agendadas,
        veces_apartado,
        views,
        getSpecs,
        specs,
    }

  },
});
